import { nanoid } from "nanoid";

export function mondayURL(slug: string, path: string) {
  return `${process.env.MONDAY_SCHEME}${slug}.${process.env.MONDAY_DOMAIN}/${path}`;
}

const isHEX = (h: string) => !isNaN(parseInt(h, 16));
const RFC4122Len = [8, 4, 4, 4, 12];

export function isGuidValid(guid: string) {
  if (!guid) {
    return false;
  }
  // Must have 36 Chars per codepoint (32 + 4 dashes)
  const codePointLenght = [...guid].length;
  if (codePointLenght !== 36) return false;

  // Must have 5 groups
  const groups = guid.split("-");
  if (groups.length !== 5) return false;

  // RFC defines 5 groups of 8-4-4-4-12 codepoints chars each
  const lenMatch = groups.map((gr) => [...gr].length).every((len, i) => len === RFC4122Len[i]);
  if (!lenMatch) return false;

  // Finally join groups (without the "-" separators and check for each char is HEX)
  return [...groups.join("")].every(isHEX);
}

// to make it more secure, we keep the path to go back to in a cookie.
// the cookie name is a unique hash passed as a `state` param.
// when the callback of this url is called, we check that a cookie with that name exist, and if true
// we redirect to that path.
// it the cookie doesn't exist, it means that this client didn't generate that url and is invalid.
export function generateRedirectURL(url: string = "", keepPath = true, params: Record<string, string> = {}) {
  const hashedCookieName = nanoid();
  const currentPath = keepPath
    ? Object.entries(params).reduce((acc, [key, value]) => {
        acc += encodeURIComponent(`#${key}=${value}`);
        return acc;
      }, window.location.pathname)
    : "/";
  const expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 1);
  document.cookie = `${hashedCookieName}=${currentPath}; path=/; samesite=strict; secure; expires=${expirationDate.toUTCString()}`;
  const paramSign = url.includes("?") ? "&" : "?";
  const redirectURL = `${url}${paramSign}state=${hashedCookieName}`;
  return redirectURL;
}

export function getPathFromState(state?: string) {
  if (!state) {
    throw new Error("No state provided");
  }
  const cookie = document.cookie
    .split(";")
    .find((c) => c.trim().startsWith(state))
    ?.split("=")[1];
  if (!cookie) {
    return "/";
  }
  // remove cookie
  document.cookie = `${state}=; path=/; samesite=strict; secure; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  return decodeURIComponent(cookie);
}

export const getDocumentIdFromURL = () => {
  return window.location.pathname.split("/d").at(-1)?.replace("/", "") ?? "";
};
